import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
    // url: 'https://auth.pemmdb.entsoe.eu/auth',
    url: 'https://pemmdb-auth.rtei-europe.com/auth',

    realm: 'pemmdb',
  //   clientId: 'react-local',
    // clientId: 'react-app-uat',
  clientId: 'react-app-dev',
    // clientId: 'react-app-prod',
    onLoad: 'login-required'
});

export default keycloak;