module.exports = global.config = {
   //BACK_URL: "https://localhost:8444/api/",
    BACK_URL: "https://pemmdb-dev.rtei-europe.com/api/",
    // BACK_URL: "https://pemmdb.entsoe.eu/api/",
    // BACK_URL: "https://pemmdb-uat.rtei-europe.com/api/",
    // KIBANA_URL:"https://pemmdb-uat.rtei-europe.com/kibana"
    // KIBANA_URL:"https://pemmdb.entsoe.eu/kibana"
    // KIBANA_URL: "https://pemmdb-dev.rtei-europe.com/kibana"
};

